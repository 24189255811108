import { render, staticRenderFns } from "./Game_shows.vue?vue&type=template&id=0b2ce029&scoped=true&"
import script from "./Game_shows.vue?vue&type=script&lang=js&"
export * from "./Game_shows.vue?vue&type=script&lang=js&"
import style0 from "./Game_shows.vue?vue&type=style&index=0&id=0b2ce029&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b2ce029",
  null
  
)

export default component.exports