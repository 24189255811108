<template>
  <div class="gameShows_container">
    <van-nav-bar
      :title="title"
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'"
    >
      <i
        slot="left"
        class="iconfont icon-jiantou1"
        @click="$router.back()"
        :color="!$isLinkGame ? '#ffd400' : '#fff'"
      ></i>
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'">{{
          title
        }}</span>
      </template>
    </van-nav-bar>
    <div class="gameshow" v-for="(item, index) in gameshowInfo" :key="index">
      <van-loading v-if="isShowsLoading" />
      <p
        v-html="item.method_explain"
        class="gamestitle"
        v-else-if="!isShowsLoading && lotteryType === 'method'"
      ></p>
      <p
        v-html="item.odds_explain"
        class="gamestitle"
        v-else-if="!isShowsLoading && lotteryType === 'odds'"
      ></p>
    </div>
  </div>
</template>

<script>
import { getLotteryType } from '@/api/lottery.js'
export default {
  name: 'GameShows',
  components: {},
  data() {
    return {
      type: JSON.parse(window.localStorage.LOTTERY).type,
      gameshowInfo: [],
      title: this.$route.query.title,
      title: "",
      isShowsLoading: true,
      lotteryType: this.$route.query.lotteryType,
    }
  },
  mounted() {},
  created() {
    this.getLotteryInfo()
  },
  methods: {
    //   获取彩票数据信息
    async getLotteryInfo() {
      const {
        data: { ret, msg, data },
      } = await getLotteryType({
        type: this.type,
        explain: 1,
      })
      if (ret === 1) {
        this.gameshowInfo = data
        this.isShowsLoading = false
      } else {
        this.$toast(msg)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.gameShows_container {
  .van-nav-bar {
    width: 100%;
    height: 100px;
    position: fixed;
  }
  /deep/ .van-nav-bar__title {
    // color: #fff;
  }
  /deep/ .van-nav-bar__content {
    // background: #ff0000;
    .iconfont {
      font-size: 44px;
      // color: #fff;
    }
  }
  .gameshow {
    box-sizing: border-box;
    // padding: 100px 15px 0 35px;
    padding: 100px 20px 30px 20px;
    width: 100%;
    .gamestitle {
      font-size: 39px;
      width: 100%;
    }
  }
}
</style>
